import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { RootState } from "../../redux/reducers/Index";
import { IAuthReducerState } from "../../redux/reducers/AuthReducer";

import "lodash";

import ROUTES from "../../constant/routes";
import helper from "../../utils/helper";

import { ITermsOfUse } from "../../interfaces/userInterface";
import getTermsOfUse from "../../services/termsOfUse";

import footerLogo from "../../images/logo.png";

import "./Footer.scss";

const Footer: React.FC = () => {
  const sanitizer = DOMPurify.sanitize;
  const { t: translation } = useTranslation();
  const [data, setData] = useState<ITermsOfUse[]>([]);
  const [category, setCategory] = useState<number>(2);
  const authData: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  /**
   * Get about us
   */

  const getAboutOfUsData = async () => {
    const Res = await getTermsOfUse({ id: category });
    const dataRes = Res?.data?.data;
    setData(dataRes);
    setCategory(2);
  };

  useEffect(() => {
    getAboutOfUsData();
  }, [category]);
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-5">
              <div className="footer-about">
                <div className="footer-logo">
                  <img src={footerLogo} alt="Footer Logo" />
                </div>
                {data?.map((test, index) => {
                  const firstDot = test.content.indexOf(".") + 1;

                  const content = test.content.slice(0, firstDot);
                  return (
                    <div className="brif" key={index}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(content as string),
                        }}
                      />
                    </div>
                  );
                })}
                
              </div>
              <div className="footer-social">
                <ul>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9 col-lg-7">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="footer-link-list">
                    <div className="heading">
                      <h3>{translation("resources").toUpperCase()}</h3>
                    </div>
                    <div className="footer-links">
                      <ul>
                        <li>
                          <Link to={ROUTES.ARTICLES}>
                            {translation("articles")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.VIDEOS}>
                            {helper.capitalizeFirstLetter(
                              translation("videos")
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.PARTNERS}>
                            {translation("partners")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="footer-link-list">
                    <div className="heading">
                      <h3>{translation("get_startted").toUpperCase()}</h3>
                    </div>
                    <div className="footer-links">
                      <ul>
                        {authData.isLoggedIn === false?
                        <li>
                          <Link to={ROUTES.SIGN_UP}>
                            {translation("register")}
                          </Link>
                        </li>
                        :null
                        }
                        {authData.isLoggedIn === false ? (
                          <li>
                            <Link to={ROUTES.LOGIN}>
                              {translation("set_goals")}
                            </Link>
                          </li>
                        ) : (
                          <li>
                            <Link to={ROUTES.SET_YOUR_GOAL}>
                              {translation("set_goals")}
                            </Link>
                          </li>
                        )}

                        {authData.isLoggedIn === false ? (
                          <li>
                            <Link to={ROUTES.LOGIN}>
                              {translation("get_plans")}
                            </Link>
                          </li>
                        ) : (
                          <li>
                            <Link to={ROUTES.GET_PLAN}>
                              {translation("get_plans")}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="footer-link-list">
                    <div className="heading">
                      <h3>{translation("company").toUpperCase()}</h3>
                    </div>
                    <div className="footer-links">
                      <ul>
                        <li>
                          <Link to={ROUTES.FAQ}>
                            {translation("faq").toUpperCase()}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.TERMS_OF_SERVICE}>
                            {translation("terms_of_service")}
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.TECH_SUPPORT}>
                            {translation("tech_support")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="footer-link-list">
                    <div className="heading">
                      <h3>{translation("contact").toUpperCase()}</h3>
                    </div>
                    <div className="footer-links">
                      <ul>
                        <li>
                          <Link to={ROUTES.GET_IN_TOUCH}>
                            {translation("contact_us")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/">+1 (999) 111-9999</Link>
                        </li>
                        <li>
                          <a href="mailto:admin@semuto.com">admin@semuto.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p>{translation("all_right_reserved")}</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
